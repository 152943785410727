<template>
	<v-content>
		<v-col cols="12" sm="6" md="3" v-if="name_disp">
			<v-text-field label="氏名" placeholder="氏名を入力してください"></v-text-field>
		</v-col>
	
		<v-col cols="12" sm="6" md="3" v-if="name_kana_disp">
			<v-text-field label="氏名（カナ）" placeholder="氏名（カナ）を入力してください"></v-text-field>
		</v-col>
	
		<v-col cols="12" sm="6" md="3" v-if="birth_disp">
			<v-text-field label="お誕生日" placeholder="0000年00月00日"></v-text-field>
		</v-col>
	</v-content>
</template>

<script>

export default {
	data: () => ({
		name_disp: true,
		name_kana_disp: true,
		birth_disp: true,
	}),
	methods: {
		snslink(links) {
			this.$router.push(links);
		}
	}
}
</script>

<style scoped>
.list {
	padding: 0;
	border-bottom: 1px solid #DEDEDF;
}

.graytext {
	color: rgba(157, 157, 157, 0.6);
}
</style>